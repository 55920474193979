// src/TransactionDetails.js
import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { useParams } from 'react-router-dom';
import './css/TrackingPage.css'; // Import the CSS file

const TransactionDetails = () => {
    const { trackingNumber } = useParams();
    const [transactionData, setTransactionData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTransactionData = async () => {
            try {
                const db = getDatabase();
                const transactionRef = ref(db, 'mashtransactions/' + trackingNumber);
                const snapshot = await get(transactionRef);

                if (snapshot.exists()) {
                    setTransactionData(snapshot.val());
                } else {
                    setError('Transaction not found');
                }
            } catch (err) {
                setError('Error fetching data: ' + err.message);
            }
        };

        fetchTransactionData();
    }, [trackingNumber]);

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    if (!transactionData) {
        return <div>Loading...</div>;
    }

    return (
        <div className="transaction-container">
            <div className="scrollable-content">
                <div className="track-no">
                   <h1> <strong>Track No: <a href="#">{transactionData.trackingNumber}</a></strong></h1>
                </div>
                <div className="payment-info">
                    <div className="oval blue"></div>
                    <div className="payment-detail">
                        <p>Payment of <strong>{transactionData.amount} {transactionData.currency}</strong> made by the buyer from their {transactionData.typeOfTransfer} transfer via {transactionData.via}</p>
                        <span>{transactionData.submittedAt}</span>
                    </div>
                </div>
                <div className="transaction-status">
                    <div className="oval yellow"></div>
                    <p>Transaction processed successfully by {transactionData.via} to be deposited in your Account.</p>
                </div>
                <div className="action-section">
                    <div className="oval blue"></div>
                    <div className="action-details">
                        <p><strong>Accept payment via the open trade in the respective account.</strong></p>
                        <p className="required-text"><strong>REQUIRED:</strong></p>
                        <p> Link respective account to complete this step.</p>
                        <a href="/login" className="link-action">Receive Payment</a>
                        <br/>
                        <a href="/login" className="link-action cancel">Cancel The Transfer</a>
                        <p>Once initiated, the transfer process can only be completed or cancelled by the seller.</p>
                    </div>
                </div>
                <div className="confirmation-section">
                    <div className="oval blue"></div>
                    <p>Funds received by the Seller</p>
                    <span>Instantly</span>
                </div>
                <div className="customer-details">
                    <div className="detail-column">
                        <h4 className="blue">Customer Details</h4>
                        <div><strong>Bank Name:</strong> {transactionData.bankname}</div>
                        <div><strong>Name:</strong> {transactionData.name}</div>
                        <div><strong>Account: </strong><a href="#">{transactionData.account}</a></div>
                    </div>
                </div>
                <div className="transaction-details">
                    <div className="detail-column">
                        <h4 className="blue">Transaction Details</h4>
                        <div><strong>Transaction ID:</strong> {transactionData.transactionID}</div>
                        <div className='detail-notes'><strong>Notes</strong>:{transactionData.notes}</div>
                    </div>
                </div>
                <div className="footer-container">
                    <p>Author payment made via PayGoAccept</p>
                </div>
            </div>
        </div>
    );
};

export default TransactionDetails;
