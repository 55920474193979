import React, { useEffect, useState } from 'react';
import { database, ref, onValue, update } from './firebase';
import './css/update.css';

const UpdateForm = () => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [formData, setFormData] = useState({
    account: '',
    amount: '',
    currency: '',
    name: '',
    notes: '',
    transaction: '',
    trackingNumber: '',
    transactionID: '',
    typeOfTransfer: '',
    via: '',
  });

  useEffect(() => {
    const transactionsRef = ref(database, 'mashtransactions');
    const unsubscribe = onValue(transactionsRef, (snapshot) => {
      const data = snapshot.val();
      const transactionsArray = data
        ? Object.entries(data).map(([key, value]) => ({
            ...value, // Use the actual trackingNumber field from the object
            id: key, // Keep the Firebase key separately if needed for reference
          }))
        : [];
      setTransactions(transactionsArray);
    });

    return () => unsubscribe();
  }, []);

  const handleTrackingChange = (e) => {
    const selectedTransaction = transactions.find(
      (t) => t.trackingNumber === e.target.value
    );
    if (selectedTransaction) {
      setFormData(selectedTransaction);
    } else {
      setFormData({
        account: '',
        amount: '',
        currency: '',
        name: '',
        notes: '',
        transaction: '',
        trackingNumber: '',
        transactionID: '',
        typeOfTransfer: '',
        via: '',
      });
    }
    setTrackingNumber(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const transactionRef = ref(database, `mashtransactions/${formData.transactionID}`);
    update(transactionRef, formData)
      .then(() => {
        alert('Transaction updated successfully!');
      })
      .catch((error) => {
        console.error('Error updating transaction: ', error);
      });
  };

  return (
    <div>
      <h2>Update Transaction</h2>

      {/* Dropdown to select the tracking number */}
      <select value={trackingNumber} onChange={handleTrackingChange}>
        <option value="">Select a Tracking Number</option>
        {transactions.map((transaction) => (
          <option key={transaction.transactionID} value={transaction.trackingNumber}>
            {transaction.trackingNumber}
          </option>
        ))}
      </select>

      {/* Display tracking number at the top */}
      {trackingNumber && (
        <div className="tracking-number">
          <h3>Tracking Number:</h3>
          <input
            type="text"
            id="trackingNumber"
            value={formData.trackingNumber} // Show the selected tracking number here
            readOnly
            onFocus={(e) => e.target.select()} // Allow the user to copy the tracking number
            style={{ cursor: 'copy' }} // Change cursor to indicate copyability
          />
        </div>
      )}

      {trackingNumber && (
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="account"
              value={formData.account}
              onChange={handleChange}
              placeholder="Account"
            />
            <input
              type="text"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
              placeholder="Amount"
            />
            <input
              type="text"
              name="currency"
              value={formData.currency}
              onChange={handleChange}
              placeholder="Currency"
            />
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name"
            />
            <input
              type="text"
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              placeholder="Notes"
            />
            <input
              type="text"
              name="transaction"
              value={formData.transaction}
              onChange={handleChange}
              placeholder="Transaction"
            />
            <input
              type="text"
              name="transactionID"
              value={formData.transactionID}
              onChange={handleChange}
              placeholder="Transaction ID"
            />
            <input
              type="text"
              name="typeOfTransfer"
              value={formData.typeOfTransfer}
              onChange={handleChange}
              placeholder="Type of Transfer"
            />
            <input
              type="text"
              name="via"
              value={formData.via}
              onChange={handleChange}
              placeholder="Via"
            />
            <button type="submit">Update Transaction</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default UpdateForm;
