// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, ref, get,onValue,update,push } from "firebase/database"; // Get only getDatabase

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAhgrMZQcgggFfh0HfOsm9I0GxuqD0bS6Y",
  authDomain: "mashlink-a8fc9.firebaseapp.com",
  databaseURL: "https://mashlink-a8fc9-default-rtdb.firebaseio.com",
  projectId: "mashlink-a8fc9",
  storageBucket: "mashlink-a8fc9.appspot.com",
  messagingSenderId: "810263531341",
  appId: "1:810263531341:web:a796a86a0b693fa0baf104",
  measurementId: "G-BQJ1H86EFR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app); // Initialize Realtime Database

// Export necessary objects
export { database, ref, get,onValue,update,push };