import React from 'react';
import { useNavigate } from 'react-router-dom';
import './css/Homepage.css'; // Ensure you update the CSS file to match the purple theme
import { Helmet } from 'react-helmet-async';

const Homepage = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/login');
  };

  const handleTransaction = () => {
    navigate('/tracking');
  };

  return (
    <div className="homepage-container">

      <Helmet>
        <title>PayGoAccept || Reliable || Transparent</title>
      </Helmet>

      {/* Header */}
      <header className="header">
        <div className="header-info">
          <p><i className="fas fa-map-marker-alt"></i> </p>
          <p><i className="fas fa-clock"></i> PayGoAccept</p>
        </div>
        <div className="header-contact">
          <p><i className="fas fa-envelope"></i> support@PayGoAccept.com</p>
          <p><i className="fas fa-phone"></i> +44 20 7946 0958</p>
        </div>
      </header>

      {/* Hero Section */}
      <div className="hero purple-theme">
        <div className="hero-overlay"></div>
        <div className="hero-content">
          <h1 className="hero-title">PayGoAccept</h1>
          <p className="sub-text">Seamlessly Move Your Money Around the World</p>
          <p>Connect your mobile wallet to PayGoAccept and experience secure, fast, and global money transfers like never before.</p>
        </div>
        <div className="hero-buttons">
          <button className="btn purple" onClick={handleTransaction}>Track Your Transfer</button>
          <button className="btn light-purple" onClick={handleNavigation}>Accept a Payment</button>
          <button className="btn grey" onClick={handleNavigation}>Cancel a Transaction</button>
        </div>
      </div>

      {/* About Us Section */}
      <section className="about-us">
        <h2>About PayGoAccept</h2>
        <p>
          At PayGoAccept, we offer a trusted platform for peer-to-peer money transfers, giving users the flexibility to send and receive funds globally with the highest level of security and transparency. Our goal is to simplify international money movement, so you can focus on what matters most.
        </p>
      </section>

      {/* Our Services Section */}
      <section className="services">
        <h2>Our Services</h2>
        <div className="services-container">
          <div className="service-card">
            <div className="service-icon"><i className="fas fa-lock"></i></div>
            <h3>Trusted and Secure</h3>
            <p>Our robust encryption ensures that your transactions are safe and protected, every time.</p>
          </div>
          <div className="service-card">
            <div className="service-icon"><i className="fas fa-globe"></i></div>
            <h3>Global Coverage</h3>
            <p>With PayGoAccept, you can send money across borders quickly, reaching over 100 countries worldwide.</p>
          </div>
          <div className="service-card">
            <div className="service-icon"><i className="fas fa-mobile-alt"></i></div>
            <h3>Mobile Wallet Integration</h3>
            <p>Connect your preferred mobile wallet to PayGoAccept and make transactions effortlessly from your phone.</p>
          </div>
          <div className="service-card">
            <div className="service-icon"><i className="fas fa-sync"></i></div>
            <h3>Real-time Tracking</h3>
            <p>Stay informed with real-time tracking of your transfers, ensuring full transparency from start to finish.</p>
          </div>
        </div>
      </section>

      {/* Get Started Section */}
      <section className="get-started">
        <h2>Get Started with PayGoAccept Today!</h2>
        <p>Join thousands of users who trust PayGoAccept for fast, easy, and secure global money transfers.</p>
        <button className="btn purple" onClick={handleNavigation}>Create Your Account</button>
      </section>

      {/* Footer */}
      <footer className="footer">
        <p>&copy; 2024 PayGoAccept. All rights reserved. | Terms of Service | Privacy Policy</p>
      </footer>
    </div>
  );
};

export default Homepage;
