import React, { useState } from 'react';

import { ref, push } from "firebase/database";
import { database } from "./firebase";

const AddUserLogin = () => {
  const [username, setUsername] = useState('');
  const [adminPassword, setAdminPassword] = useState('');

  const handleAddUser = (e) => {
    e.preventDefault();

    // Reference to the 'userlogins' table in Realtime Database
    const userLoginsRef = ref(database, 'userlogins');

    // Pushing a new user login entry
    push(userLoginsRef, {
      username: username,
      adminPassword: adminPassword,
    })
      .then(() => {
        console.log('User added successfully');
        setUsername('');
        setAdminPassword('');
      })
      .catch((error) => {
        console.error('Error adding user:', error);
      });
  };

  return (
    <form onSubmit={handleAddUser}>
      <div>
        <label>Username:</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Admin Password:</label>
        <input
          type="password"
          value={adminPassword}
          onChange={(e) => setAdminPassword(e.target.value)}
          required
        />
      </div>
      <button type="submit">Add User</button>
    </form>
  );
};

export default AddUserLogin;
