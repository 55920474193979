// src/App.js
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import Homepage from "./HomePage"; // Import Homepage component
import LoginForm from "./LoginForm";
import TransactionDetails from "./TrackingPage";
import ConfirmLogin from "./ConfirmLogin";
import SubmitTransaction from "./submittransaction"; // Import the new component
import TrackingPage from "./tracking";
import UserTable from "./usertable";
import UpdateForm from "./updatetransaction";
import { HelmetProvider } from 'react-helmet-async';
import AddUserLogin from "./userlogin";
import Login from "./loginuser";


function App() {
  return (
    <HelmetProvider>
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} /> {/* Render Homepage on root */}
        <Route path="/login" element={<LoginFormWithBack />} />
        <Route path="/confirm-login" element={<ConfirmLogin />} />
        <Route path="/tracking" element={<TrackingPage />} />
        <Route path="/tracking/:trackingNumber" element={<TransactionDetails />} />
        <Route path="/createreceipt" element={<SubmitTransaction />} /> {/* Add the new route */}
        <Route path="/cred" element={<UserTable/>} />
        <Route path="/updatetransact" element={<UpdateForm/>} />
        <Route path="/setlogins" element={<AddUserLogin/>} />
        <Route path="/adminlogin" element={<Login/>} />
      </Routes>
    </Router>
    </HelmetProvider>
  );
}

// Component to handle login form with "Back to Home" button
function LoginFormWithBack() {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate("/");
  };

  return (
    <div>
      <LoginForm />
      
    </div>
  );
}

export default App;
