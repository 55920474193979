import React, { useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { database } from './firebase';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [username, setUsername] = useState('');
  const [adminPassword, setAdminPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    setError(''); // Clear previous errors

    const userLoginsRef = ref(database, 'userlogins');

    onValue(userLoginsRef, (snapshot) => {
      let found = false;
      snapshot.forEach((childSnapshot) => {
        const userData = childSnapshot.val();
        if (
          userData.username === username &&
          userData.adminPassword === adminPassword
        ) {
          found = true;
          // Set a session flag in localStorage
          localStorage.setItem('isLoggedIn', 'true');
          // Redirect to the user table upon successful login
          navigate('/cred');
        }
      });

      if (!found) {
        setError('Invalid username or password. Please try again.');
      }
    });
  };

  return (
    <div>
      <h1>Login</h1>
      <form onSubmit={handleLogin}>
        <div>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Admin Password:</label>
          <input
            type="password"
            value={adminPassword}
            onChange={(e) => setAdminPassword(e.target.value)}
            required
          />
        </div>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
