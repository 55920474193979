// src/SubmitTransaction.js
import React, { useState, useEffect } from 'react';
import { getDatabase, ref, set } from 'firebase/database';
import './css/SubmitTransaction.css';
import { useNavigate } from 'react-router-dom';


const SubmitTransaction = () => {
    const [formData, setFormData] = useState({
        amount: '',
        typeOfTransfer: '',
        currency: '',
        bankname: '',
        name: '',
        account: '',
        transactionID: '',
        notes: '',
        via: '',
        trackingNumber: '', // Added tracking number
        submittedAt: ''
    });
    const navigate = useNavigate();

    // Function to generate tracking number
    const generateTrackingNumber = () => {
        const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const digits = '0123456789';
        let trackingNumber = '';

        // First character must be a letter
        trackingNumber += letters.charAt(Math.floor(Math.random() * letters.length));

        // Next 9 characters can be letters or digits
        for (let i = 1; i < 10; i++) {
            const charSet = Math.random() < 0.4 ? letters : digits; // 40% letters, 60% digits
            trackingNumber += charSet.charAt(Math.floor(Math.random() * charSet.length));
        }

        return trackingNumber;
    };

    useEffect(() => {
        const trackingNumber = generateTrackingNumber();
        setFormData((prevData) => ({
            ...prevData,
            trackingNumber: trackingNumber // Set generated tracking number
        }));
    }, []); // Only runs on component mount

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const formatDate = (date) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const isPM = hours >= 12;
        const period = isPM ? 'p.m.' : 'a.m.';
        hours = hours % 12 || 12; // Convert to 12-hour format, handle midnight
    
        const formattedDate = `${day} ${month} ${year} ${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
        return formattedDate;
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Get current time and subtract 5 minutes
        const currentDate = new Date();
        currentDate.setMinutes(currentDate.getMinutes() - 5);
    
        // Format the date as "31 Jul 2024 11:05 p.m."
        const formattedDate = formatDate(currentDate);
    
        // Ensure the amount has trailing ".00" if necessary
        //let formattedAmount = parseFloat(formData.amount).toFixed(2);
    
        const db = getDatabase();
        const transactionRef = ref(db, 'mashtransactions/' + formData.transactionID.toUpperCase());
    
        // Prepare the data to submit, including the formatted amount and datetime
        const dataToSubmit = {
            ...formData,
            amount: formData.amount, // Set the formatted amount
            transactionID: formData.transactionID.toUpperCase(),
            submittedAt: formattedDate // Add the formatted datetime string
        };
    
        // Send the data to Firebase
        await set(transactionRef, dataToSubmit);
        alert('Transaction submitted successfully!');
    
        // Navigate to the tracking page with the generated transaction ID
        navigate(`/tracking/${formData.transactionID.toUpperCase()}`);
    
        // Reset form after submission, including resetting the submittedAt field
        setFormData({
            amount: '',
            typeOfTransfer: '',
            currency: '',
            bankname: '',
            name: '',
            account: '',
            transactionID: '',
            notes: '',
            via: '',
            trackingNumber: generateTrackingNumber(),
            submittedAt: '' // Reset the submittedAt field as well
        });
    };
    
    
    return (
        <div className="scroll-container">
            <form onSubmit={handleSubmit}>
                <h2>Submit Transaction</h2>
                <div>
                    <strong>Tracking Number: {formData.trackingNumber}</strong>
                </div>
                <input type="text" name="amount" placeholder="Amount" value={formData.amount} onChange={handleChange} required />
                <input type="text" name="typeOfTransfer" placeholder="Type of Transfer" value={formData.typeOfTransfer} onChange={handleChange} required />
                <input type="text" name="currency" placeholder="Currency" value={formData.currency} onChange={handleChange} required />
                <input type="text" name="bankname" placeholder="Bankname" value={formData.transaction} onChange={handleChange} required />
                <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} required />
                <input type="text" name="account" placeholder="Account Number" value={formData.account} onChange={handleChange} required />
                <input type="text" name="transactionID" placeholder="Transaction ID" value={formData.transactionID} onChange={handleChange} required />
                <textarea name="notes" placeholder="Notes" value={formData.notes} onChange={handleChange}></textarea>
                <input type="text" name="via" placeholder="Via" value={formData.via} onChange={handleChange} required />
                <button type="submit">Submit Transaction</button>
            </form>
        </div>
    );
};

export default SubmitTransaction;
